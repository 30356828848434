.Card {
    background-color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    min-height: 150px;
    margin: 1rem 0rem;
    position: relative;
    /* width: 350px; */
    display: inline-flex;
    flex-direction: column;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
  }
  
  .Card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
  
  .Card__header {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .Card__text {
    color: #666;
    font-size: 0.875rem;
    margin: 0;
  }
  
  .Card__footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .Card__footer a {
    align-items: center;
    color: #666;
    display: flex;
    font-size: 0.875rem;
    text-decoration: none;
  }
  
  .Card__footer a:hover {
    color: #000;
  }
  
  .Card__footer a svg {
    height: 1rem;
    margin-right: 0.5rem;
    width: 1rem;
  }
  
  .Card__footer a span {
    display: inline-block;
    margin-right: 0.5rem;
  }
  
  .Card__footer a:last-child {
    margin-right: 0;
  }
  
  .Card__footer a:last-child svg {
    margin-right: 0;
  }
  
  .Card__footer a:last-child span {
    margin-right: 0;
  }
  
  .Card__footer a:last-child span:last-child {
    display: none;
  }